<template>
  <div>
    <v-tabs background-color="transparent" grow v-model="tab">
      <v-tab>Settings</v-tab>
      <v-tab>Payment</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: transparent">
      <v-tab-item>
        <settings-screen />
      </v-tab-item>
      <v-tab-item>
        <payment-screen />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import SettingsScreen from "../components/SettingsScreen.vue";
import PaymentScreen from "../components/PaymentScreen.vue";
export default {
  components: {
    SettingsScreen,
    PaymentScreen,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
