<template>
  <div>
    <div>
      <clipperUpload v-model="imgUrl" @input="showEditDialog = true">
        <div class="holder">
          <v-avatar class="ml-2" size="90">
            <img
              :src="
                currentOrganization.image ||
                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
              "
              alt="avatar"
            />
          </v-avatar>
          <span>{{ loading ? "uploading..." : "Select Image" }}</span>
        </div>
      </clipperUpload>
    </div>
    <v-dialog v-model="showEditDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Edit Image </v-card-title>
        <v-card-text>
          {{ imgUrl }}
          <clipper-basic
            ref="clipper"
            class="my-clipper"
            :src="imgUrl"
            :ratio="1"
          >
            <div slot="placeholder" class="placeholder">No image</div>
          </clipper-basic>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="uploadImage">
            Upload {{ loading ? "..." : "" }}
          </v-btn>
          <v-btn color="green darken-1" text @click="showEditDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { clipperBasic, clipperUpload } from "vuejs-clipper";
import { dataURItoBlob } from "../helpers/file-helper";

export default {
  components: {
    clipperBasic,
    clipperUpload,
  },
  data() {
    return {
      loading: false,
      showEditDialog: false,
      imgUrl: "",
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  mounted() {
    if (this.currentOrganization) {
      this.imgUrl = this.currentOrganization.image;
    }
  },
  methods: {
    changeImage() {
      const canvas = this.$refs.clipper.clip();
      const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
      this.imgUrl = dataUrl;
      const imageBlob = dataURItoBlob(dataUrl);
      this.imageBlob = imageBlob;
    },
    async uploadImage() {
      try {
        this.showEditDialog = false;
        this.loading = true;
        this.changeImage();
        const imageRef = await firebase
          .storage()
          .ref(`organization_images/${this.currentOrganization.id}.jpg`)
          .put(this.imageBlob);
        const imageUrl = await imageRef.ref.getDownloadURL();
        await this.updateUserImageUrl(imageUrl);
        this.$store.dispatch("auth/fetchCurrentOrganization");
        this.loading = false;
        this.$toast.success("Image Changed");
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toast.error("Failed to change Image");
      }
    },
    async updateUserImageUrl(imageUrl) {
      console.log(imageUrl);
      console.log(this.currentOrganization);
      await firebase
        .firestore()
        .collection("Organizations")
        .doc(this.currentOrganization.id)
        .set(
          {
            image: imageUrl,
          },
          {
            merge: true,
          }
        );
    },
  },
};
</script>
<style scoped>
.holder {
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
}
</style>
