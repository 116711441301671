<template>
  <div class="mt-3">
    <profile-image-changer />
    <h3>Set Authorization Policy</h3>
    <v-radio-group
      v-model="authorizationPolicy"
      @change="changePolicy()"
      class="ml-6"
    >
      <v-radio value="PLUGIN" class="mb-3">
        <template v-slot:label>
          <div class="settings-label">Plugin</div>
        </template>
      </v-radio>
      <v-radio value="MOBILE">
        <template v-slot:label>
          <div class="settings-label">Mobile</div>
        </template>
      </v-radio>
    </v-radio-group>
    <h3>Set Remuneration Plan</h3>
    <v-radio-group
      v-model="remunerationPlan"
      @change="changeRemunerationPlan()"
      class="ml-6"
    >
      <v-radio value="Monthly" class="mb-3">
        <template v-slot:label>
          <div class="settings-label">Monthly</div>
        </template>
      </v-radio>
      <v-radio value="Quaterly" class="mb-3">
        <template v-slot:label>
          <div class="settings-label">Quaterly</div>
        </template>
      </v-radio>
      <v-radio value="Annual" class="mb-3">
        <template v-slot:label>
          <div class="settings-label">Annual</div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>
<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import ProfileImageChanger from "./ProfileImageChanger.vue";

export default {
  components: {
    ProfileImageChanger,
  },
  data() {
    return {
      authorizationPolicy: "MOBILE",
      remunerationPlan: "Monthly",
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },

  mounted() {
    this.initializeValues();
  },

  methods: {
    async changePolicy() {
      try {
        await this.updateOrganization({
          verification_type: this.authorizationPolicy,
          use_plugin: this.authorizationPolicy === "PLUGIN",
        });
        this.$toast.success("Successully changed policy");
        this.$emit("policyChanged");
      } catch (error) {
        this.errorMessage = error.message
          ? error.message
          : "Failed to change policy";
        this.$toast.error(this.errorMessage);
      }
    },
    async changeRemunerationPlan() {
      try {
        await this.updateOrganization({
          remuneration: this.remunerationPlan,
        });
        this.$toast.success("Successully changed plan");
        this.$emit("remunerationChanged");
      } catch (error) {
        this.errorMessage = error.message
          ? error.message
          : "Failed to change plan";
        this.$toast.error(this.errorMessage);
      }
    },

    async updateOrganization(update) {
      await firebase
        .firestore()
        .collection("Organizations")
        .doc(this.currentOrganization.id)
        .update(update);
    },
    initializeValues() {
      this.remunerationPlan = this.currentOrganization.remuneration;
      this.authorizationPolicy = this.currentOrganization.verification_type;
      if (this.remunerationPlan) {
        this.$emit("remunerationChanged");
      }
      if (this.authorizationPolicy) {
        this.$emit("policyChanged");
      }
    },
  },
};
</script>
<style scoped>
.settings-label {
  font-weight: bold;
  font-size: 1.1rem;
  margin-left: 15px;
}
</style>
