<template>
  <v-list-item background-color="transparent">
    <v-list-item-content>
      <v-list-item-title
        v-text="'XXXX XXXX XXXX ' + card.card.last4"
      ></v-list-item-title>

      <v-list-item-subtitle
        v-text="`Expires: ${card.card.exp_month}/${card.card.exp_year}`"
      ></v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        text
        color="primary"
        @click="showDeleteDialog = true"
        :disabled="deleting"
      >
        {{ deleting ? "Deleting..." : "Delete" }}
      </v-btn>
    </v-list-item-action>
    <v-dialog v-model="showDeleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Delete Card </v-card-title>
        <v-card-text>Are you sure you want to delete this card?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteCard()">
            Delete
          </v-btn>
          <v-btn color="green darken-1" text @click="showDeleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>
<script>
import axios from "@axios";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
      showDeleteDialog: false,
    };
  },
  methods: {
    async deleteCard() {
      try {
        this.showDeleteDialog = false;
        this.deleting = true;
        const token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          "/organisation/detach-payment-methods",
          {
            paymentMethodId: this.card.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.$toast.success("Card deleted successfully");
        this.$emit("cardDeleted");
      } catch (error) {
        this.$toast.error("Failed to delete card");
      }
      this.deleting = false;
    },
  },
};
</script>
