<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="min-height: 55vh"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div
      v-else-if="errorMessage || emptyMessage"
      class="d-flex justify-center align-center"
      style="min-height: 55vh"
    >
      <div class="d-flex flex-column justify-center align-center mx-1">
        <!-- <img
          v-if="errorMessage"
          class="load-img"
          :src="require('@/assets/images/failed.svg')"
        />
        <img
          v-else
          class="load-img"
          :src="require('@/assets/images/empty.svg')"
        /> -->
        <span class="error-text">{{ errorMessage || emptyMessage }}</span>
        <slot name="messageExtra" />
      </div>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
    emptyMessage: {
      type: String,
      default: null,
    },
  },
};
</script>
<style scoped>
.error-text {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.load-img {
  width: 200px;
  max-width: 200px;
  margin-bottom: 20px;
}
</style>
