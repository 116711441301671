<template>
  <div class="mt-3">
    <load-handler
      :loading="loading"
      :error-message="errorMessage"
      :empty-message="emptyMessage"
    >
      <v-list subheader two-line style="background-color: transparent">
        <payment-card-display
          v-for="card in cards"
          :key="card.id"
          :card="card"
          @cardDeleted="fetchCards()"
        />
      </v-list>
    </load-handler>
    <v-btn
      class="mx-2"
      fab
      dark
      color="primary"
      style="position: fixed; bottom: 20px; right: 20px"
      @click="showAddDialog = true"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <v-dialog v-model="showAddDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Add Card </v-card-title>
        <v-card-text>
          <card-add-display
            ref="cardAddDisplay"
            @cardAdded="
              showAddDialog = false;
              fetchCards();
            "
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showAddDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "@axios";
import CardAddDisplay from "./CardAddDisplay.vue";
import LoadHandler from "./LoadHandler.vue";
import PaymentCardDisplay from "./PaymentCardDisplay.vue";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  components: { CardAddDisplay, LoadHandler, PaymentCardDisplay },
  data() {
    return {
      showAddDialog: false,
      loading: false,
      errorMessage: "",
      emptyMessage: "",
      cards: [],
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  mounted() {
    if (this.currentOrganization.stripeCustomerId) {
      this.fetchCards();
    } else {
      this.errorMessage = "No card found";
    }
  },
  methods: {
    async fetchCards() {
      try {
        this.loading = true;
        this.errorMessage = "";
        this.emptyMessage = "";
        console.log(this.currentOrganization);

        const token = await firebase.auth().currentUser.getIdToken();
        const response = await axios.post(
          "/organisation/list-payment-methods",
          {
            organizationId: this.currentOrganization.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // let customerId = this.currentOrganization.stripeCustomerId;
        // console.log(customerId);
        // const response = await axios.get(
        //   `https://us-central1-paytalk-429bf.cloudfunctions.net/listCustomerPaymentMethodsNony?customerId=${customerId}`
        // );

        console.log("customer cards response");
        console.log(response.data);

        const cards = response.data.data;
        this.cards = cards;
        console.log("Cards");
        console.log(cards);
        if (cards.length === 0) {
          this.emptyMessage = "No Card found";
        }
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message ? error.message : "Error cards";
      }
      this.loading = false;
    },
  },
};
</script>
